const env = {
    url: 'https://api.lummefy.com.br/',
    api: 'https://api.lummefy.com.br/api/v1/',
    applicationName: 'Lummefy',
    applicationFooter: 'Lummefy',
    chartBarBackgroundColor: '#2a4a80',
    title: 'Lummefy - A plataforma para mentorias mais completa do Brasil',
    pusher_key: 'b1dbc1a672ac1b2543b0',
    websocket_server: 'chat.lummefy.com.br',
    websocket_port: 443,
    websocket_ssl: true,
    pusher_cluster: 'sa1',
    url_mentee: 'https://mentorado.lummefy.com.br/'
}

export default env

