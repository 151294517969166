export const state = {
    mentee: [],
    tasks: [],
}

export const mutations = {
    setMentee(state, payload) {
        state.mentee = payload;
    },

    setTasks(state, payload) {
        state.tasks = payload;
    },
}

export default {
    namespaced: true,
    state,
    mutations
};
