const routes = [
    {
        path: '/cadastrar-mentor',
        name: 'register',
        component: () => import('../views/public/register.vue'),
        meta: {
            title: 'Realizar Cadastro'
        }
    },
    {
        path: '/checkout',
        name: 'checkout',
        component: () => import('../views/public/checkout.vue'),
        meta: {
            title: 'Checkout'
        }
    },
    {
        path: '',
        name: 'public',
        // import('../views/public/public.vue')
        component: () => import('../views/public/register.vue'),
        meta: {
            title: 'Lummefy'
        }
    },
    {
        path: '/cadastrar-senha/:token',
        name: 'new-password',
        component: () => import('../views/auth/new-password.vue'),
        meta: {
            title: 'Cadastrar Senha'
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/auth/login.vue'),
        meta: {
            title: 'Login'
        }
    },
    {
        path: '/login/credenciais/:token?',
        name: 'login-credentials',
        component: () => import('../views/auth/credentials.vue'),
        meta: {
            title: 'Login'
        }
    },
    {
        path: '/esqueci-a-senha',
        name: 'forgot-password',
        component: () => import('../views/auth/forgot-password.vue'),
        meta: {
            title: 'Esqueci a Senha'
        }
    },
    {
        path: '/alterar-senha',
        name: 'change-password',
        component: () => import('../views/auth/change-password.vue'),
        meta: {
            title: 'Alterar Senha'
        }
    },

    {
        path: '/meu-perfil',
        name: 'My-profile',
        component: () => import('../views/user/my-profile.vue'),
        meta: {
            title: 'Meu Perfil',
            auth: true
        },
    },


    {
        path: '/home',
        name: 'home',
        component: () => import('../views/dashboard/index.vue'),
        meta: {
            title: 'Dashboard',
            auth: true
        },
    },

    {
        path: '/meu-perfil',
        name: 'my-profile',
        component: () => import('../views/dashboard/my-profile.vue'),
        meta: {
            title: 'Meu perfil',
            auth: true
        },
    },
    {
        path: '/mentorias',
        name: 'mentoring-list',
        component: () => import('../views/mentoring/list/index.vue'),
        meta: {
            title: 'Mentorias',
            auth: true
        }
    },
    {
        path: '/mentorias/cadastrar/:id?',
        name: 'mentoring-register',
        component: () => import('../views/mentoring/register/index.vue'),
        meta: {
            title: 'Cadastro de Mentoria',
            auth: true
        }
    },

    {
        path: '/agenda',
        name: 'calendar',
        component: () => import('../views/calendar/index.vue'),
        meta: {
            title: 'Agenda do Mentor',
            auth: true
        }
    },

    {
        path: '/formulario-de-aplicacao/:id?',
        name: 'form-application',
        component: () => import('../views/form-application/index.vue'),
        meta: {
            title: 'Formulário de Aplicação',
            auth: true
        }
    },

    {
        path: '/mentorias/detalhes/:id?',
        name: 'mentoring-details',
        component: () => import('../views/mentoring/details/index.vue'),
        meta: {
            title: 'Detalhes Mentoria',
            auth: true
        }
    },

    {
        path: '/mentorias/detalhes/:mentoring/mentorando/:mentee',
        name: 'mentee-mentoring',
        component: () => import('../views/mentee/index.vue'),
        meta: {
            title: 'Mentorando',
            auth: true
        }
    },

    {
        path: '/mentorias/detalhes/:mentoring/mentorando/:mentee/atividade/:content',
        name: 'mentee-mentoring-activity',
        component: () => import('../views/mentee/activity.vue'),
        meta: {
            title: 'Resposta de Atividade',
            auth: true
        }
    },

    {
        path: '/mentorias/detalhes/:mentoring/mentorando/:mentee/formulario/:content/:form',
        name: 'mentee-mentoring-form',
        component: () => import('../views/mentee/form.vue'),
        meta: {
            title: 'Resposta de Formulário',
            auth: true
        }
    },

    {
        path: '/formularios',
        name: 'forms-list',
        component: () => import('../views/forms/list.vue'),
        meta: {
            title: 'Formulários',
            auth: true
        }
    },
    {
        path: '/formularios/cadastrar/:id?',
        name: 'forms-register',
        component: () => import('../views/forms/register.vue'),
        meta: {
            title: 'Cadastrar Formulário',
            auth: true
        }
    },
    {
        path: '/mensagens/:id?',
        name: 'chat',
        component: () => import('../views/chat/chat.vue'),
        meta: {
            title: 'Mensagens',
            auth: true
        }
    },

    {
        path: '/cupons',
        name: 'coupon',
        component: () => import('../views/coupon/index.vue'),
        meta: {
            title: 'Cupons',
            auth: true
        }
    },

    {
        path: '/cupons/cadastrar/:id?',
        name: 'coupon-register',
        component: () => import('../views/coupon/register.vue'),
        meta: {
            title: 'Cadastrar Cupom',
            auth: true
        }
    },

    {
        path: '/mentorados',
        name: 'leads',
        component: () => import('../views/leads/index.vue'),
        meta: {
            title: 'Mentorados',
            auth: true
        }
    },

    {
        path: '/vendas',
        name: 'sales',
        component: () => import('../views/sale/index.vue'),
        meta: {
            title: 'Vendas',
            auth: true
        }
    },

    {
        path: '/financeiro',
        name: 'financial',
        component: () => import('../views/financial/index.vue'),
        meta: {
            title: 'Financeiro',
            auth: true
        }
    },

    {
        path: '/notificacoes',
        name: 'notifications',
        component: () => import('../views/dashboard/notifications.vue'),
        meta: {
            title: 'Notificações',
            auth: true
        }
    },
]

module.exports = {
    routes
}
