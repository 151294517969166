export const state = {
    modules: [],
}

export const mutations = {
    setModules(state, payload) {
        state.modules = payload;
    },

    resetModules(state) {
        state.modules = [];
    },

    addModule(state, question) {
        state.modules.push(question);
    },

    editModule(state, payload) {
        state.modules[payload.index] = payload.module;
    },

    removeModule(state, index) {
        state.modules.splice(index, 1);
    },

    removeContent(state, data) {
        state.modules[data.index].contents.splice(data.i, 1);
    },
}

export const actions = {
    getModules(context, index) {
        return state.modules[index];
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
