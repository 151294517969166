export const state = {
    questions: [],
}

export const mutations = {
    setQuestions(state, payload) {
        state.questions = payload;
    },

    resetQuestions(state) {
        state.questions = [];
        state.questions_deleted = [];
        state.alternatives_deleted = [];
    },

    addQuestion(state, question) {
        state.questions.push(question);
    },

    editQuestion(state, payload) {
        state.questions[payload.index] = payload.question;
    },

    removeQuestion(state, index) {
        state.questions.splice(index, 1);
    },

    removeAlternative(state, data) {
        state.questions[data.index].alternatives.splice(data.i, 1);
    },
}

export default {
    namespaced: true,
    state,
    mutations
};
