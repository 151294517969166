import $ from 'jquery'
import {convertDateText, convertHour} from "@/components/composables/masks";

// import store from "@/state/store";

export function getUrl(session) {
    const values = JSON.parse(localStorage.getItem(session));
    let url = values.url + '?';
    const params = values.params;
    const keys = Object.keys(values.params);

    for (let i = 0; i < keys.length; i++) {
        if (keys[i] !== 'orderBy' && keys[i] !== 'with') {
            let result = params[keys[i]];
            if (result !== '') {
                if (i > 0) url += '&';
                url += `${keys[i]}=${result}`;
            }
        } else if (keys[i] === 'with') {
            params[keys[i]].forEach(el => {
                url += `&${keys[i]}[]=${el}`;
            })
        } else  {
            if (params['order_type'] === 'ASC') {
                url += `&orderByAsc=${params['order_by']}`;
            } else {
                url += `&orderByDesc=${params['order_by']}`;
            }
        }
    }

    return url;
}

export function newOrder(order_type, seq, session) {
    let obj = JSON.parse(localStorage.getItem(session))
    let icons = document.querySelectorAll('th i');
    for (let i = 0; i < icons.length; i++) {
        icons[i].classList.remove('active');
    }

    const id = order_type + seq;

    document.getElementById(id).classList.add('active');

    obj.params.orderBy = seq.toUpperCase();
    obj.params.order_type = seq.toUpperCase();
    obj.params.order_by = order_type;


    obj.params.page = 1;

    localStorage.setItem(session, JSON.stringify(obj));

    // const url = getUrl(session)
    // store.dispatch('api/getApi', url);

}

export function loadAddress(i) {
    var cep = $('#zipCode' + i).val().replace(/\D/g, '');
    var validacep = /^[0-9]{8}$/;
    console.log(validacep);
    if (validacep.test(cep)) {
        $.getJSON("https://viacep.com.br/ws/" + cep + "/json/?callback=?", function (dados) {
            if (!("erro" in dados)) {
                $("#uf" + i).val(dados.uf);
                $("#city" + i).val(dados.localidade);
                // $("#neighborhood" + i).val(dados.bairro);
                // $("#address" + i).val(dados.logradouro);
            }
        });
    }
}

export function showHide(show, hide, save) {
    if (save) {
        const card = document.getElementById(save);
        const height = card.offsetHeight;
        card.style.height = height + 'px';
    }
    $('#' + hide).hide();
    $('#' + show).show(300);
}

export function getUrlVideo(url) {
    if (url && url.includes('youtube')) {
        let youtubeUrl = 'https://www.youtube.com/embed/';
        const arr = url.split('?v=');
        return youtubeUrl + arr[1];
    } else {
        return 'https://www.youtube.com/embed/5mq1QJqtXak'
    }
}

/**
 * @description Converter data do formato JS para string no formato dd Mês AAAA HH:mm
 * @param {string} date data no formato JS
 * @return {string} string da data convertida
 */
export function dateIso(date) {
    const dataObj = new Date(date);
    const dataFormatada = `${dataObj.getUTCFullYear()}-${dataObj.getUTCMonth() + 1}-${dataObj.getUTCDate() < 10 ? '0' + dataObj.getUTCDate() : dataObj.getUTCDate()} ${dataObj.getUTCHours() < 10 ? '0' + dataObj.getUTCHours() : dataObj.getUTCHours()}:${dataObj.getMinutes() < 10 ? '0' + dataObj.getMinutes() : dataObj.getMinutes()}`

    return `${convertDateText(dataFormatada)} ${convertHour(dataFormatada)}`;
}

export function getTextActivity(content) {
    console.log(content)

    return 1;
}

export function hoverHandler(isHovered, id) {
    const dropdown = document.getElementById(id);
    if (isHovered) {
        dropdown.style.opacity = '1';
        dropdown.style.visibility = 'visible';
    } else {
        dropdown.style.opacity = '0';
        dropdown.style.visibility = 'hidden';
    }
}

export function encondeId(id) {
    return btoa(id.toString());
}
