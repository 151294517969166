import http from "@/http";
import {getUrl} from "@/components/composables/functions";
import {endLoading, endLoadTable} from "@/components/composables/spinners";

export const state = {
    listAll: {},
    listCards: {},
    listMessages: {},
    showSpinner: false,
    cards: {
        value_total_sales: 0,
        total_sales: 0,
        total_mentorings: 0,
        total_mentees: 0,
    }
}

export const mutations = {

    setListAll(state, newValue) {
        state.listAll = newValue;
    },

    setCards(state, newValue) {
        state.cards = newValue;
    },

    setMessages(state, newValue) {
        state.listMessages = newValue;
    },

    addMessage(state, message) {
        const filteredMessages = state.listMessages.filter(list => list.mentee_id !== message.mentee_id);

        state.listMessages = [
            message,
            ...filteredMessages
        ];
    },

    setList(state, newValue) {
        const page = newValue.page || 0;
        const limit = document.getElementById('limitFilter') ? document.getElementById('limitFilter').value : 0;
        const count = newValue.count || 0;
        newValue.start = count > 0 ? page * limit + 1 : 0;
        newValue.partial = count <= (page + 1) * limit ? count : (page + 1) * limit;

        state.listAll = newValue

        let after = state.listCards.data || {};
        after = Object.values(after);
        let arr = [];

        for (let i = 0; i < Object.keys(after).length; i++) {
            arr.push(after[i]);
        }

        const newArr = newValue.data || [];

        for (let i = 0; i < newArr.length; i++) {
            arr.push(newArr[i]);
        }

        state.listCards = {
            page,
            limit,
            count,
            partial: count <= (page + 1) * limit ? count : (page + 1) * limit,
            data: arr
        };
    },

    resetValues(state, newValue) {
        state.listAll = newValue
        state.listCards = newValue
    },

    setSpinner(state, newValue) {
        state.showSpinner = newValue
    },
}

export const actions = {
    // eslint-disable-next-line no-unused-vars
    init({state, dispatch}) {
        dispatch('validate')
    },

    setCards({commit}) {
        http.get('mentors/dashboard')
            .then(response => {
                commit('setCards', response.data);
            })
    },

    getApi({commit}, url) {
        http.get(url, {
                headers: {'Authorization': ` Bearer ${localStorage.getItem('token')} `}
            })
            .then(response => {
                commit('setList', response.data);
                endLoadTable();
                if (document.getElementById('spinnerTable')) document.getElementById('spinnerTable').style.display = 'none';
                if (document.getElementById('spinnerCard')) document.getElementById('spinnerCard').style.display = 'none';
            })
            .catch(error => {
                endLoadTable();
                console.error(error);
                // Forbidden(error);
            })
    },

    async insert({dispatch}, data) {
        let url = data.url;
        let formData = data.formData;
        if (formData.id > 0) {
            url += `/${formData.id}`;
            formData._method = 'PUT';
            delete formData.password;
        }
        return await http.post(url, formData, {
                headers: {'Authorization': ` Bearer ${localStorage.getItem('token')} `}
            })
            .then(() => {

                dispatch('getApi', getUrl(data.session));
                if (document.getElementById('spinnerCard')) document.getElementById('spinnerCard').style.display = 'none';
                return true;
            })
            .catch(error => {
                if (typeof error.response.data === "object") {
                    for (let item in error.response.data) {
                        console.log(item);
                        // notifyError(error.response.data[item][0]);
                    }
                } else {
                    // Forbidden(error);
                }
                endLoading('form', 'save');
                console.error(error);
                return false;
            })
    },

    async delete({commit, dispatch}, data) {
        const session = JSON.parse(localStorage.getItem(data.session));
        const id = data.id;
        const url = `${session.url}/${id}`;
        return await http.delete(url, {
                headers: {'Authorization': ` Bearer ${localStorage.getItem('token')} `}
            })
            .then(() => {
                // pegando os valores com js
                let start = document.getElementById('start').innerText;
                let partial = document.getElementById('partial').innerText;
                let count = document.getElementById('count').innerText;

                --partial;
                --count;

                // pegando os valores da store
                let newValues = state.listAll;

                // Se tive o valor total for menor que o inicio da lista,
                // diminuir o page e fazer nova busca
                if (partial === count && start > count) {
                    const newSession = session.params.page = newValues.page;
                    localStorage.setItem(session, newSession);
                    dispatch('getApi', session.url);
                    // se acabarem os itens desta página mas existirem mais páginas a frente,
                    //     fazer nova busca na mesma page
                } else if (partial < start && start < count) {
                    dispatch('getApi', session.url);
                    // se o total for 0, esvazia o objeto data para aparecer mensagem na tabela
                } else if (count === 0) {
                    newValues.data = {};
                    commit('resetValues', newValues);
                    --start;
                    document.getElementById('start').innerText = start;
                }

                document.getElementById('partial').innerText = partial;
                document.getElementById('count').innerText = count;
                document.getElementById('line' + id).style.display = 'none';
            })
            .catch(error => {
                console.error(error);
                // Forbidden(error);
            })
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
